import React from "react";
import {
  navigationWrapper,
  arrowDisabled,
  arrow,
  arrowLeft,
  arrowRight,
  dots_container,
  dot,
  lineContainer,
  line,
  active,
  arrowLeftLine,
  arrowRightLine,
  lineWrapper,
} from "./Carousel.module.scss";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const defaultOptions = {
  initial: 0,
  spacing: 10,
  autoHeight: true,
  breakpoints: {
    "(min-width: 768px)": {
      slidesPerView: 2,
      mode: "free-snap",
    },
    "(min-width: 1200px)": {
      slidesPerView: 3,
      mode: "free-snap",
    },
  },
};
const Carousel = ({
  options = defaultOptions,
  children,
  enableArrows = true,
  enableDots = false,
  enableLine = false,
  className,
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    ...options,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  return (
    <div className={`${navigationWrapper} ${className}`}>
      <div ref={sliderRef} className="keen-slider">
        {children}
      </div>
      {slider && enableArrows && (
        <>
          <ArrowLeft
            onClick={(e) => e.stopPropagation() || slider.prev()}
            disabled={currentSlide === 0}
          />
          <ArrowRight
            onClick={(e) => e.stopPropagation() || slider.next()}
            disabled={currentSlide === slider.details().size - 1}
          />
        </>
      )}
      {slider && enableDots && !enableLine ? (
        <div className={dots_container}>
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                type="button"
                aria-label="dot"
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
                className={`${dot} ${currentSlide === idx ? active : ""}`}
              />
            );
          })}
        </div>
      ) : slider && enableLine && !enableDots ? (
        <div className={lineWrapper}>
          <ArrowLeft
            className={arrowLeftLine}
            onClick={(e) => e.stopPropagation() || slider.prev()}
            disabled={currentSlide === 0}
          />
          <div className={lineContainer}>
            {[
              ...Array(
                slider.details().size - (slider.details().slidesPerView - 1)
              ).keys(),
            ].map((idx) => {
              return (
                <button
                  type="button"
                  aria-label="line"
                  key={idx}
                  onClick={() => {
                    slider.moveToSlideRelative(idx);
                  }}
                  className={`${line} ${currentSlide === idx ? active : ""}`}
                />
              );
            })}
          </div>
          <ArrowRight
            className={arrowRightLine}
            onClick={(e) => e.stopPropagation() || slider.next()}
            disabled={currentSlide === slider.details().size - 1}
          />
        </div>
      ) : null}
    </div>
  );
};

function ArrowLeft(props) {
  const { className, ...rest } = props;
  const disabled = props.disabled ? arrowDisabled : "";

  return (
    <svg
      onClick={props.onClick}
      className={`${arrow} ${arrowLeft} ${disabled} ${className}`}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="20" cy="20" r="19.5" stroke="#2D2D2D" />
      <path d="M22 26.6666L16 20.3333L22 14" stroke="#2D2D2D" />
    </svg>
  );
}

function ArrowRight(props) {
  const { className, ...rest } = props;
  const disabled = props.disabled ? arrowDisabled : "";
  return (
    <svg
      onClick={props.onClick}
      className={`${arrow} ${arrowRight} ${disabled} ${className}`}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="20" cy="20" r="19.5" stroke="#2D2D2D" />
      <path d="M18 14L24 20.3333L18 26.6667" stroke="#2D2D2D" />
    </svg>
  );
}

export default Carousel;
