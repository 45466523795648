// extracted by mini-css-extract-plugin
export var active = "Carousel-module--active--R7NRm";
export var arrow = "Carousel-module--arrow--AxLOk";
export var arrowDisabled = "Carousel-module--arrowDisabled--9HCFc";
export var arrowHidden = "Carousel-module--arrowHidden--KJQIP";
export var arrowLeft = "Carousel-module--arrowLeft--JalXc";
export var arrowLeftLine = "Carousel-module--arrowLeftLine--B+au6";
export var arrowRight = "Carousel-module--arrowRight--9bdw+";
export var arrowRightLine = "Carousel-module--arrowRightLine--6Oun7";
export var dot = "Carousel-module--dot--NHOu2";
export var dots_container = "Carousel-module--dots_container--B7Dqj";
export var line = "Carousel-module--line--IkyLn";
export var lineContainer = "Carousel-module--lineContainer--T5SOi";
export var lineWrapper = "Carousel-module--lineWrapper--B4Li5";
export var navigationWrapper = "Carousel-module--navigationWrapper--gQ6b1";