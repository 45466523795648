import React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/Layout";
import StandardBanner from "../../../components/StandardHero/StandardHero";
import VideoFeaturePanel from "../../../components/VideoFeaturePanel/VideoFeaturePanel";
import Carousel from "../../../components/Carousel/Carousel";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  customVideoFeaturePanel,
  customCarousel,
  customSlide,
  carouselSection,
  slideContainer,
  logo,
  meetTeamContent,
  meetTeamContainer,
  customTimelineCarousel,
  timelineContainer,
  personasContainer,
  pageLinksHeadingContainer,
  pageLinksContainer,
} from "./index.module.scss";
import { useMedia } from "../../../hooks/useMedia";
import { Button } from "../../../components/Button/Button";
import TimelineCard from "../../../components/TimelineCard/TimelineCard";
import RightArrowIcon from "../../../images/icons/rightArrowIcon.svg";
import { Link } from "@reach/router";
import "keen-slider/keen-slider.min.css";
import parse from "html-react-parser";

const WhyAmx = ({
  data,
  data: {
    strapiWhyAmx,
    strapiWhyAmx: {
      featurePanel,
      Testimonials: testimonialsData,
      meetTeam: { copy, heading, cta, headerImage },
      timelineHeading,
      timeline: timelinesData,
      pageLinksHeading,
      metaData,
    },
  },
}) => {
  const bannerImage =
    strapiWhyAmx.Hero.image.localFile.childImageSharp.gatsbyImageData;
  const bannerTitle = "";
  const showBreadcrumb = strapiWhyAmx.Hero.showBreadcrumb;

  const breadcrumbs = [{ name: "THE AMX STORY" }];

  const personas = [
    { name: "investors", link: "/solutions/investors/pension-schemes" },
    { name: "asset managers", link: "/solutions/asset-managers" },
    { name: "consultants", link: "/solutions/consultants" },
    { name: "service partners", link: "/solutions/service-partners" },
  ];

  const { mediaType } = useMedia(
    // Media queries
    ["(min-width: 1440px)", "(min-width: 800px)", "(min-width: 375px)"],
    // (relates to above media queries by array index)
    [
      {
        mediaType: "desktop",
      },
      {
        mediaType: "tablet",
      },

      { mediaType: "mobile" },
    ],
    // Default object
    { mediaType: "mobile" }
  );

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: bannerImage,
        article: false,
      }}
    >
      <StandardBanner
        image={bannerImage}
        title={bannerTitle}
        showBreadcrumb={showBreadcrumb}
        breadcrumbs={breadcrumbs}
      />
      <VideoFeaturePanel
        data={featurePanel}
        className={customVideoFeaturePanel}
      />

      <section className={carouselSection}>
        <Carousel
          className={customCarousel}
          options={{
            initial: 0,
            loop: true,
            slidesPerView: 1,
          }}
          enableDots={true}
          enableArrows={mediaType === "mobile" ? false : true}
        >
          {testimonialsData.map(({ Copy, Name, Role, Logo }) => (
            <div className={`keen-slider__slide ${customSlide}`}>
              <div className={slideContainer}>
                <h4>{parse(Copy)}</h4>
                <p>{Name}</p>
                <p className="ui">{Role}</p>
                <GatsbyImage
                  className={logo}
                  image={Logo.localFile.childImageSharp.gatsbyImageData}
                  alt={Logo.alternativeText}
                />
              </div>
            </div>
          ))}
        </Carousel>
      </section>

      <section className={meetTeamContainer}>
        <img src={headerImage.localFile.publicURL} alt="logo" />
        <div className={meetTeamContent}>
          <h2>{heading}</h2>
          <p>{parse(copy)}</p>
          {cta.map(({ text }) => (
            <Button
              label={text}
              bkgColor="white"
              type="internalLink"
              toPage="/about/meet-our-team/"
            />
          ))}
        </div>
      </section>

      <section className={timelineContainer}>
        <h2>{timelineHeading}</h2>
        <Carousel
          className={customTimelineCarousel}
          options={{
            initial: 0,
            mode: "free",
            centered: true,
            slidesPerView: 1,
            breakpoints: {
              "(min-width: 768px)": {
                slidesPerView: 1,
                mode: "free",
                centered: false,
              },
              "(min-width: 1024px)": {
                slidesPerView: 2,
                mode: "free",
                centered: false,
              },

              "(min-width: 1440px)": {
                slidesPerView: 3,
                mode: "free",
                centered: false,
              },
            },
          }}
          enableLine={true}
          enableArrows={false}
        >
          {timelinesData.map((timeline, index) => {
            return (
              <div key={index} className={`keen-slider__slide`}>
                <TimelineCard timelineCardData={timeline} />
              </div>
            );
          })}
        </Carousel>
      </section>

      <section className={personasContainer}>
        <div className={pageLinksHeadingContainer}>
          <h2>{pageLinksHeading}</h2>
        </div>
        <ul className={pageLinksContainer} role="list">
          {personas.map(({ name, link }) => (
            <li>
              <Link className="titles" to={link}>
                {name}
                <img src={RightArrowIcon} alt="right-arrow" />
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiWhyAmx {
      timeline {
        copy
        year
        icon {
          alternativeText
          localFile {
            publicURL
          }
        }
      }
      Hero {
        showBreadcrumb
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }

      metaData {
        pageTitle
        metadescription
      }

      pageLinksHeading
      timelineHeading
      featurePanel {
        copy
        heading
        mainImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          alternativeText
        }
        headerImage {
          alternativeText
          localFile {
            publicURL
          }
        }
        video {
          video {
            localFile {
              publicURL
            }
          }
          transcript {
            localFile {
              id
              publicURL
            }
          }
        }
      }
      Testimonials {
        Copy
        Name
        Role
        Logo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 200
                quality: 100
                webpOptions: { quality: 100 }
                placeholder: TRACED_SVG
              )
            }
          }
          alternativeText
        }
      }
      meetTeam {
        copy
        heading
        headerImage {
          alternativeText
          localFile {
            publicURL
          }
        }
        cta {
          text
        }
      }
    }
  }
`;

export default WhyAmx;
