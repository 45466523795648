import React from "react";

import {
  timelineCardContainer,
  yearContainer,
  copyContainer,
} from "./TimelineCard.module.scss";

const TimelineCard = ({ timelineCardData: { copy, year, icon } }) => {
  return (
    <div className={timelineCardContainer}>
      <div className={yearContainer}>
        <h3>{year}</h3>
        <img src={icon.localFile.publicURL} alt="" />
      </div>
      <div className={copyContainer}>
        <p>{copy}</p>
      </div>
    </div>
  );
};

export default TimelineCard;
